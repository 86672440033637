















import { Component, Prop, Vue } from "vue-property-decorator";

import axios from "axios";

@Component({
  components: {},
})
export default class ChronologicList extends Vue {
  composersBirthdate: any[] = [];

  async refreshComposersBirthdate() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/composers/birthdate"
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        console.log(result.data.results.bindings);
        const composers = result.data.results.bindings.map((rc: any) => {
          return {
            name: rc.composerLabel.value,
            birthdate: rc.birthdate?.value,
            dateOfDeath: rc.dateOfDeath?.value,
            qnr: rc.composer.value.split("/").slice(-1)[0],
          };
        });
        this.composersBirthdate = composers;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load composers");
    }
  }

  formatPreciseDate(birthdate: string, precision: number) {
    if (precision == 11) {
      return new Date(birthdate).toLocaleDateString()
    }
    else if (precision == 9) {
      return new Date(birthdate).getFullYear();
    }
    else return birthdate;
  }

  formatBirthAndDeath(birthdate: string, dateOfDeath: string | null) {
    if (birthdate && !dateOfDeath) return new Date(birthdate).getFullYear();
    else if (birthdate && dateOfDeath) 
      return new Date(birthdate).getFullYear() + "-" + new Date(dateOfDeath).getFullYear();
    else return "";
  }

  async mounted() {
    await this.refreshComposersBirthdate();
  }
}

<template>
  <div class="composer container">
    <ComposerDialog />
  </div>
</template>

<script>
import ComposerDialog from "@/components/composer/ComposerDialog.vue";
export default {
  name: "Composer",
  components: {
    ComposerDialog,
  },
};
</script>

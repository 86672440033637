



































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

import CompositionCard from "@/components/general/CompositionCard.vue";

import { marked } from "marked";

interface ComposerData {
  name?: string;
  birthdate?: Date;
  dobPrecision?: number;
  birthplace?: string;
  dateOfDeath?: Date;
  dodPrecision?: number;
  placeOfDeath?: string;
  biography?: string;
  literature?: string;
  links?: string;
  stateOfEditing?: string;
}

@Component({
  components: { CompositionCard },
})
export default class ComposerDialog extends Vue {
  composer: ComposerData | any = null;

  compositions: any = [];

  async refreshComposer() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/composer/" + this.$route.params.qno
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        const composer = result.data.results.bindings[0];

        let newComposer: ComposerData = {};

        console.log(composer);
        newComposer.name = composer.composerLabel?.value ?? "";
        newComposer.birthdate = composer.birthdate?.value ?? "";
        newComposer.dobPrecision = composer.dobPrecision?.value ?? 11;
        newComposer.birthplace = composer.birthplaceLabel?.value ?? "";
        newComposer.dateOfDeath = composer.dateOfDeath?.value ?? "";
        newComposer.dodPrecision = composer.dodPrecision?.value ?? 11;
        newComposer.placeOfDeath = composer.placeOfDeathLabel?.value ?? "";
        newComposer.biography = composer.biography?.value ?? "";
        newComposer.literature = composer.literature?.value ?? "";
        newComposer.links = composer.links?.value ?? "";
        newComposer.stateOfEditing = composer.stateOfEditing?.value ?? "";

        this.composer = newComposer;

        await this.fillLongText("biography");
        await this.fillLongText("literature");
        await this.fillLongText("stateOfEditing");

        console.log(this.composer);
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load composer");
    }
  }

  async refreshCompositions() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl +
          "query/composer/compositions/" +
          this.$route.params.qno
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        this.compositions = result.data.results.bindings;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load compositions");
    }
  }

  formatPreciseDate(birthdate: string, precision: number) {
    if (precision == 11) {
      return new Date(birthdate).toLocaleDateString();
    } else if (precision == 9) {
      return new Date(birthdate).getFullYear();
    } else return birthdate;
  }

  goTo(composition: any) {
    const qno: string = composition.composition.value.split("/").slice(-1)[0];
    this.$router.push({ name: "composition", params: { qno } });
  }

  async fillLongText(columnName: string) {
    const idCheck = /^#(.*)?#$/;
    const field: string = this.composer[columnName];
    const matches = field.match(idCheck);
    // should be empty or undefined if not found
    if (matches && matches[1]) {
      console.log("Loading: " + matches[1]);
      try {
        const result = await axios.get(
          this.$store.state.apiUrl + "query/longtext/" + matches[1]
        );
        if (result.data.status == "error")
          this.$root.$emit("toast", "error", result.data.message);
        else {
          this.composer[columnName] = result.data.text;
          if (result.data.format == "markdown") {
            this.composer[columnName + "Marked"] = marked(result.data.text);
          }
        }
      } catch (e) {
        this.composer[columnName] = null;
        console.log("Loading %s for column %s failed", matches[1], columnName);
      }
    }
  }

  async mounted() {
    await this.refreshComposer();
    await this.refreshCompositions();
  }
}





























































































































import { Component, Prop, Vue } from "vue-property-decorator";

const halfmoon = (window as any).halfmoon;

interface StringMap {
  [key: string]: string;
}

const toastClasses: StringMap = {
  error: "alert-danger",
  success: "alert-success",
  info: "alert-primary",
  warn: "alert-secondary",
};

const toastTitles: StringMap = {
  error: "Fehler",
  success: "Erfolg",
  info: "Information",
  warn: "Warnung",
};

@Component
export default class App extends Vue {
  /* showToast(type : string, message : string) {
        (window as any).$('body').toast({
            displayTime: 2000,
            class: type,
            message: message
        });
    } */

  showToast(type: string, message: string) {
    const title = toastTitles[type];
    const alertType = toastClasses[type];
    halfmoon.initStickyAlert({
      content: message,
      title: title,
      alertType: alertType,
      hasDismissButton: true,
      timeShown: 120000,
    });
  }

  mounted() {
    if (localStorage.isDarkMode) this.isDarkMode = this.getDarkMode();
    this.setDarkMode(this.isDarkMode);
  }

  isDarkMode: boolean;

  changeBodyDarkModeClass(mode: boolean) {
    // assume there is always one body element in a Vue.js application
    // this should be safe in all cases without testing actual existence programmatically
    const bodyElement: HTMLBodyElement =
      document.getElementsByTagName("body")[0];

    if (mode) {
      bodyElement.classList.add("dark-mode");
    } else {
      bodyElement.classList.remove("dark-mode");
    }
  }

  setDarkMode(mode: boolean) {
    this.changeBodyDarkModeClass(mode);
    localStorage.isDarkMode = mode;
  }

  getDarkMode(): boolean {
    if (localStorage.isDarkMode == "true") return true;
    else if (localStorage.isDarkMode == "false") return false;
    else return false;
  }

  toggleSidebar() {
    halfmoon.toggleSidebar();
  }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;

    this.setDarkMode(this.isDarkMode);
  }

  constructor() {
    super();
    this.$root.$on("toast", this.showToast);

    this.isDarkMode = this.getDarkMode();
    this.setDarkMode(this.isDarkMode);
  }
}

<template>
  <div class="contact container">
    <h1>Kontakt</h1>
    <ContactOverview />
  </div>
</template>

<script>
import ContactOverview from "@/components/komposer/ContactOverview.vue";

export default {
  name: "Contact",
  components: {
    ContactOverview,
  },
};
</script>















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Pagination extends Vue {
  @Prop() step!: number;
  @Prop() total!: number;
  @Prop() offset!: number;

  internalOffset = 0;

  setOffset(internalOffset: number) {
    this.internalOffset = internalOffset;
    //console.log("internalOffset: %s", this.internalOffset);
    this.$emit("offset", internalOffset);
    this.$forceUpdate();
  }

  getLow(page: number, step: number) {
    return page * step + 1;
  }

  getHigh(page: number, step: number) {
    const high = (page + 1) * step;
    if (high > this.total) return this.total;
    else return (page + 1) * step;
  }

  getActivePage(page: number) {
    //console.log("%s == %s * %s", this.internalOffset, page, this.step);
    return this.internalOffset == page * this.step;
  }

  get pages() {
    let pages = [];
    for (let page = 0; page < this.total / this.step; page++) {
      pages.push(page);
    }
    return pages;
  }

  mounted() {
    this.internalOffset = this.offset;
  }
}

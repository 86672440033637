<template>
  <div class="search container">
    <h1>Suche nach Werken</h1>
    <SearchForm />
  </div>
</template>

<script>
import SearchForm from "@/components/search/SearchForm.vue";
import Creations from "@/components/creations/Creations.vue";

export default {
  name: "Search",
  components: {
    SearchForm,
  },
};
</script>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// const prefixPath = process.env.VUE_APP_PREFIX_PATH ?? "";

// function cleanURL(str : string) : string {
//   return str.replace(/([^:]\/)\/+/g, "$1");
// }

export default new Vuex.Store({
  state: {
    rootUrl:
      process.env.NODE_ENV == "production"
        ? "/komposer/"
        : "http://localhost:3000/komposer/",
    publicUrl:
      process.env.NODE_ENV == "production"
        ? "/komposer/"
        : "http://localhost:3000/komposer/",
    apiUrl:
      process.env.NODE_ENV == "production"
        ? "/komposer/api/"
        : "http://localhost:3000/komposer/api/",
    assetsUrl:
      process.env.NODE_ENV == "production"
        ? "/komposer/assets/"
        : "http://localhost:3000/komposer/assets/",
    logoutUrl:
      process.env.NODE_ENV == "production"
        ? "/komposer/logout"
        : "http://localhost:3000/logout",
    // save query parameters and results during usage
    query: {
      composer: "",
      category: "",
      duration: "",
      texter: "",
      timeOfOrigin: "",
    },
    chosenLineups: [],
    availability: "",
    offset: 0,
    compositions: []
  },
  mutations: {
    setQuery(state, query) {
      state.query = query
    },
    setChosenLineups(state, chosenLineups) {
      state.chosenLineups = chosenLineups;
    },
    setAvailability(state, availability) {
      state.availability = availability;
    },
    setOffset(state, offset) {
      state.offset = offset
    },
    setCompositions(state, compositions) {
      state.compositions = compositions;
    }
  },
  actions: {},
  modules: {},
});

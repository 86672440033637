





























































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

import { marked } from "marked";

interface CompositionData {
  composerLabel: string;
  composerQNo: string;
  name: string;
  subtitle?: string;
  addition?: string;
  premiere?: string;
  publishing?: string;
  recordings?: string;
  category?: string;
  categoryLabel?: string;
  emergenceFrom?: Date;
  emergenceFromNumber: number;
  emergenceTo?: Date;
  emergenceToNumber: number;
  lineupCommentary?: string;
  textAuthor?: string;
  availabilityLabel?: string;
  scope?: string;
  commentary?: string;
  literature?: string;
  setDesignation?: string;
}

@Component({
  components: {},
})
export default class CompositionDialog extends Vue {
  composition: CompositionData | any = null;
  lineups: any[] = [];

  getEmergence(emergenceFromNumber: number, emergenceToNumber: number) {
    if (emergenceFromNumber && emergenceToNumber && (emergenceToNumber - emergenceFromNumber > 1)) {
      return `${emergenceFromNumber}-${emergenceToNumber}`;
    }
    else if (emergenceToNumber) return emergenceToNumber;
    else if (emergenceFromNumber) return emergenceFromNumber;
  }

  async refreshComposition() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/composition/" + this.$route.params.qno
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        //const composition = result.data.results.bindings[0];

        //this.composition = {};

        //console.log(composition);

        // This is a bit verbose...
        /*
        this.composition.composerLabel = composition.composerLabel?.value ?? "";
        this.composition.name = composition.compositionLabel?.value ?? "";
        this.composition.subtitle = composition.subtitle?.value ?? "";
        this.composition.addition = composition.addition?.value ?? "";
        this.composition.premiere = composition.premiere?.value ?? "";
        this.composition.publishing = composition.publishing?.value ?? "";
        this.composition.category = composition.category?.value ?? "";
        this.composition.categoryLabel = composition.categoryLabel?.value ?? "";
        this.composition.emergenceFrom = composition.emergenceFrom?.value ?? "";
        this.composition.emergenceTo = composition.emergenceTo?.value ?? "";
        */

        // Start with the same construction, but map all values directly
        const intermediate = result.data.results.bindings[0];
        console.log(intermediate);
        // pairs of keys and values, like [[k1,v1],[k2,v2],[k3,v3],...]
        // in the above, every key must be associated to the corresponding value of
        // the API result. This is done here by a map function on all keys
        const tuples = Object.keys(intermediate).map((k: string) => [
          k,
          intermediate[k].value,
        ]);

        // zip keys and values together to form an object
        let composition = Object.fromEntries(tuples);

        // as "name" is called "compositionLabel" in API, simply rename:
        composition.name = composition.compositionLabel;
        delete composition.compositionLabel;

        // add composer QNo
        composition.composerQNo = composition.composer.split("/").slice(-1)[0];
        delete composition.composer;

        // delete unnecessary wikibase links
        delete composition.category;

        console.log(composition);

        this.composition = composition;

        this.formatDate();

        await this.fillLongText("commentary");
        await this.fillLongText("setDesignation");

        //console.log(this.composition);
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load composition");
    }
  }

  async fillLongText(columnName: string) {
    const idCheck = /^#(.*)?#$/;
    const field: string = this.composition[columnName];
    if (!field) return;
    const matches = field.match(idCheck);
    // should be empty or undefined if not found
    if (matches && matches[1]) {
      console.log("Loading: " + matches[1]);
      try {
        const result = await axios.get(
          this.$store.state.apiUrl + "query/longtext/" + matches[1]
        );
        if (result.data.status == "error")
          this.$root.$emit("toast", "error", result.data.message);
        else {
          this.composition[columnName] = result.data.text;
          if (result.data.format == "markdown") {
            this.composition[columnName + "Marked"] = marked(result.data.text);
          }
        }
      } catch (e) {
        this.composition[columnName] = null;
        console.log("Loading %s for column %s failed", matches[1], columnName);
      }
    }
  }

  formatDate() {
    if (this.composition.emergenceFrom)
      this.composition.emergenceFromNumber = new Date(
        this.composition.emergenceFrom
      ).getFullYear();
    else this.composition.emergenceFrom == "";
    if (this.composition.emergenceTo)
      this.composition.emergenceToNumber = new Date(
        this.composition.emergenceTo
      ).getFullYear();
    else this.composition.emergenceTo == "";
  }

  // async refreshCompositionLineup() {
  //   try {
  //     const result = await axios.get(
  //       this.$store.state.apiUrl +
  //       "query/composition/lineup/" +
  //       this.$route.params.qno
  //     );
  //     if (result.data.status == "error")
  //       this.$root.$emit("toast", "error", result.data.message);
  //     else {
  //       //console.log(result.data.results.bindings);

  //       const lineup = result.data.results.bindings.map((rc: any) => {
  //         return {
  //           count: rc.lineupCount?.value ?? "",
  //           label: rc.lineupLabel?.value ?? "",
  //           type: rc.wdpqLabel?.value ?? "",
  //         };
  //       });

  //       //console.log(lineup);
  //       this.lineups = lineup;
  //     }
  //   } catch (e: any) {
  //     console.error(e);
  //     console.error("Could not load lineup");
  //   }
  // }

  async mounted() {
    await this.refreshComposition();
    //await this.refreshCompositionLineup();
  }
}

<template>
  <div class="privacy container">
    <h1>Datenschutz</h1>
    <PrivacyOverview />
  </div>
</template>

<script>
import PrivacyOverview from "@/components/komposer/PrivacyOverview.vue";
export default {
  name: "Privacy",
  components: {
    PrivacyOverview,
  },
};
</script>































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";

import QueryOutput from "@/components/search/QueryOutput.vue";

interface ChosenLineup {
  qno: string;
  count: number;
}

@Component({
  components: { QueryOutput },
})
export default class SearchForm extends Vue {
  query = {
    composer: "",
    category: "",
    duration: "",
    texter: "",
    timeOfOrigin: "",
  };

  categories = [];

  result: any = null;

  compositions = [];

  queryRunning = false;

  offset = 0;

  lineups: any[] = [];

  availabilities: any[] = [];
  availability = "";

  composers: string[] = [];
  textauthors: string[] = [];

  chosenLineups: ChosenLineup[] = [];

  addLineup() {
    this.chosenLineups.push({
      qno: "",
      count: 0,
    });
  }

  removeLineup(idx: number) {
    console.log("idx: %s", idx);
    this.chosenLineups.splice(idx, 1);
  }

  setOffset(offset: number) {
    this.offset = offset;
    this.executeQuery();
  }

  saveSettings() {
    // save all user settings
    this.$store.commit("setQuery", this.query);
    this.$store.commit("setChosenLineups", this.chosenLineups);
    this.$store.commit("setAvailability", this.availability);
    // save navigation (offset)
    this.$store.commit("setOffset", this.offset);

    // save result
    this.$store.commit("setCompositions", this.compositions);
    console.log(this.$store.state.compositions);
  }

  async restoreSettings() {
    this.query = this.$store.state.query;
    this.chosenLineups = this.$store.state.chosenLineups;
    this.availability = this.$store.state.availability;
    this.offset = this.$store.state.offset;
    this.compositions = this.$store.state.compositions;
  }

  async executeNewQuery() {
    // it is a new query, so old offsets should be deleted
    this.offset = 0;
    this.executeQuery();
  }

  async executeQuery() {
    this.queryRunning = true;
    this.compositions = [];

    console.log("executing query");
    try {
      const queryObject = {
        query: this.query,
        offset: this.offset,
        lineups: this.chosenLineups,
        availability: this.availability,
      };
      const result = await axios.post(
        this.$store.state.apiUrl + "query/compositions",
        queryObject
      );
      console.log(result);
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else this.compositions = result.data.results.bindings;
      this.queryRunning = false;
      this.saveSettings();
    } catch (e: any) {
      if (e?.response?.status == 400) {
        this.$root.$emit("toast", "warn", "Fehlerhafte Eingabe!");
      } else
        this.$root.$emit(
          "toast",
          "error",
          "Verbindung zur Server-Schnittstelle nicht möglich:<br/>" +
            "URL: " +
            e.config.url +
            "<br/>" +
            "Nachricht: " +
            e.message
        );
      this.queryRunning = false;
    }
  }

  async refreshCategories() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/categories"
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        const categories = result.data.results.bindings.map(
          (rc: any) => rc.categoryLabel.value
        );

        this.categories = categories;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load categories");
    }
  }
  async refreshLineup() {
    try {
      const result = await axios.get(this.$store.state.apiUrl + "query/lineup");
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        const lineups = result.data.results.bindings.map((rc: any) => {
          return {
            label: rc.lineupLabel.value,
            qno: rc.lineup.value.split("/").slice(-1)[0],
          };
        });

        this.lineups = lineups;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load lineup");
    }
  }

  async refreshAvailabilities() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/availabilities"
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        const availabilities = result.data.results.bindings.map((rc: any) => {
          return {
            label: rc.availabilityLabel.value,
            qno: rc.availability.value.split("/").slice(-1)[0],
          };
        });

        this.availabilities = availabilities;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load availabilities");
    }
  }

  async refreshComposers() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/composers/alphabetic"
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        const composers = result.data.results.bindings.map(
          (rc: any) => rc.composerLabel.value
        );

        this.composers = composers;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load composers");
    }
  }

  async refreshTextAuthors() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/textauthors"
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        const textauthors = result.data.results.bindings.map(
          (rc: any) => rc.textAuthorLabel.value
        );

        this.textauthors = textauthors;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load text authors");
    }
  }

  async mounted() {
    // reactiveness. reload settings first
    await this.restoreSettings();

    await this.refreshCategories();
    await this.refreshLineup();
    await this.refreshAvailabilities();
    await this.refreshComposers();
    await this.refreshTextAuthors();
  }
}

<template>
  <div class="about container">
    <h1>Über die Datenbank</h1>
    <!-- <StartOverview /> // not needed anymore here-->
    <div class="link-box">
      <a href="#history">Historie</a>
      <a href="#systematik">Systematik</a>
      <a href="#datenmodell">Datenmodell</a>
    </div>
    <HistoryOverview />
    <SystematikOverview />
    <DatamodelOverview />
  </div>
</template>

<script>
//import StartOverview from "@/components/komposer/StartOverview.vue";
import HistoryOverview from "@/components/komposer/HistoryOverview.vue";
import SystematikOverview from "@/components/komposer/SystematikOverview.vue";
import DatamodelOverview from "@/components/komposer/DatamodelOverview.vue";

export default {
  name: "About",
  components: {
    // StartOverview,
    HistoryOverview,
    SystematikOverview,
    DatamodelOverview,
  },
};
</script>
<style scoped>
.link-box {
  border: 1px solid grey;
  padding: 1rem;
}
.link-box > a {
  display: block;
}
</style>
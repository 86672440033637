




























import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CompositionCard extends Vue {
  @Prop() composition: any;

  getEmergence(timeOfOrigin: any, timeOfOriginEnd: any) {
    let emergenceFromNumber = 0;
    let emergenceToNumber = 0;
    if (timeOfOrigin?.value) emergenceFromNumber = this.getYear(timeOfOrigin?.value);
    if (timeOfOriginEnd?.value) emergenceToNumber = this.getYear(timeOfOriginEnd?.value);
    if (
      emergenceFromNumber &&
      emergenceToNumber &&
      emergenceToNumber - emergenceFromNumber > 1
    ) {
      return `${emergenceFromNumber}-${emergenceToNumber}`;
    } else return emergenceToNumber;
  }

  getYear(dateString: string) {
    return new Date(dateString).getFullYear();
  }

  goTo(composition: any) {
    const qno: string = composition.composition.value.split("/").slice(-1)[0];
    this.$router.push({ name: "composition", params: { qno } });
  }
}
















import { Component, Prop, Vue } from "vue-property-decorator";

import axios from "axios";

@Component({
  components: {},
})
export default class AlphabeticList extends Vue {
  composersAlphabetic: any[] = [];

  async refreshComposersAlphabetic() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "query/composers/alphabetic"
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        const composers = result.data.results.bindings.map((rc: any) => {
          return {
            name: rc.composerLabel.value,
            qnr: rc.composer.value.split("/").slice(-1)[0],
          };
        });

        this.composersAlphabetic = composers;
      }
    } catch (e: any) {
      console.error(e);
      console.error("Could not load composers");
    }
  }

  async mounted() {
    await this.refreshComposersAlphabetic();
  }
}

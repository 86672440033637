<template>
  <div class="komposer container">
    <HomeOverview />
  </div>
</template>

<script>
import HomeOverview from "@/components/komposer/HomeOverview.vue";

export default {
  name: "Home",
  components: {
    HomeOverview,
  },
};
</script>

<template>
  <div class="composers container">
    <h1>Komponist:innen</h1>
    <AlphabeticList />
    <ChronologicList />
  </div>
</template>

<script>
import AlphabeticList from "@/components/alphabetic-list/AlphabeticList.vue";
import ChronologicList from "@/components/chronologic-list/ChronologicList.vue";

export default {
  name: "Composers",
  components: {
    AlphabeticList,
    ChronologicList,
  },
};
</script>

import { render, staticRenderFns } from "./PrivacyOverview.vue?vue&type=template&id=2fce638e&scoped=true&"
import script from "./PrivacyOverview.vue?vue&type=script&lang=ts&"
export * from "./PrivacyOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fce638e",
  null
  
)

export default component.exports
<template>
  <div class="composition container">
    <CompositionDialog />
  </div>
</template>

<script>
import CompositionDialog from "@/components/compositions/CompositionDialog.vue";
export default {
  name: "Composition",
  components: {
    CompositionDialog,
  },
};
</script>

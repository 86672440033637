import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import About from "../views/About.vue";
import Composers from "../views/Composers.vue";
import Search from "../views/Search.vue";
import Contact from "../views/Contact.vue";
import Imprint from "../views/Imprint.vue";
import Home from "../views/Home.vue";
import Composer from "../views/Composer.vue";
import Composition from "../views/Composition.vue";
import Privacy from "../views/Privacy.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Komposer",
    meta: {
      title: "Komposer",
    },
    component: Home,
  },
  {
    path: "/about",
    name: "Über die Datenbank",
    meta: {
      title: "Über die Datenbank",
    },
    component: About,
  },
  {
    path: "/composers",
    name: "Komponistinnen",
    meta: {
      title: "Komponist:innen",
    },
    component: Composers,
  },
  {
    path: "/search",
    name: "Suche nach Werken",
    meta: {
      title: "Suche nach Werken",
    },
    component: Search,
  },
  {
    path: "/composer/:qno",
    name: "composer",
    meta: {
      title: "Komponist:in",
    },
    component: Composer,
  },
  {
    path: "/composition/:qno",
    name: "composition",
    meta: {
      title: "Werk",
    },
    component: Composition,
  },
  {
    path: "/contact",
    name: "Kontakt",
    meta: {
      title: "Kontakt",
    },
    component: Contact,
  },
  {
    path: "/imprint",
    name: "Impressum",
    meta: {
      title: "Impressum",
    },
    component: Imprint,
  },
  {
    path: "/privacy",
    name: "Datenschutz",
    meta: {
      title: "Datenschutz",
    },
    component: Privacy,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   route level code- splitting
  // this generates a separate chunk(about.[hash].js) for this route
  // which is lazy - loaded when the route is visited.
  // component: () =>
  //   import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // }
];

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    const appName = "Komponist:innen-Datenbank Schleswig-Holstein";
    const appHead = appName + " — ";
    if (to.meta?.title) document.title = appHead + to.meta?.title;
    else document.title = appName;
  });
});

export default router;

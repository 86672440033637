






import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

import { marked } from "marked";

@Component({
  components: {},
})
export default class ImprintOverview extends Vue {
  id = "imprint";

  siteText = "";

  async renderSiteText() {
    try {
      const result = await axios.get(
        this.$store.state.apiUrl + "sitetext/" + this.id
      );
      if (result.data.status == "error")
        this.$root.$emit("toast", "error", result.data.message);
      else {
        let textToMarked = result.data.text as any;
        this.siteText = marked(textToMarked);
        console.log(result.data);
      }
    } catch (e: any) {
      this.$root.$emit(
        "toast",
        "error",
        "Verbindung zur Server-Schnittstelle nicht möglich:<br/>" +
          "URL: " +
          e.config.url +
          "<br/>" +
          "Nachricht: " +
          e.message
      );
    }
  }

  mounted() {
    this.renderSiteText();
  }
}

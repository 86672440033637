<template>
  <div class="imprint container">
    <h1>Impressum</h1>
    <ImprintOverview />
  </div>
</template>

<script>
import ImprintOverview from "@/components/komposer/ImprintOverview.vue";

export default {
  name: "Imprint",
  components: {
    ImprintOverview,
  },
};
</script>






























import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";

import Pagination from "@/components/general/Pagination.vue";
import CompositionCard from "@/components/general/CompositionCard.vue";

@Component({
  components: { Pagination, CompositionCard },
})
export default class QueryOutput extends Vue {
  @Prop() compositions: any;
  @Prop({ default: false }) queryRunning!: boolean;
  @Prop({ default: 0 }) offset!: number;

  internalOffset = 0;

  sendOffset(offset: number) {
    console.log("offset: %s", offset);
    this.internalOffset = offset;
    this.$emit("offset", offset);
  }
}
